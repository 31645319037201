import React, { useState, FormEvent } from "react";
import { Lock } from "lucide-react";

interface PasswordPopupProps {
  onAuthenticate: () => void;
}

const PasswordPopup: React.FC<PasswordPopupProps> = ({ onAuthenticate }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password === "consumeraffairsxevery") {
      onAuthenticate();
      document.cookie = "authenticated=true; max-age=31536000; path=/";
    } else {
      setError("Incorrect password");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
        <div className="flex flex-col items-center mb-6">
          <img
            src="/consumer-affairs-logo.svg"
            alt="Consumer Affairs Logo"
            className="h-16 mb-4"
          />
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <Lock
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter password"
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Access Report
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordPopup;
